<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
  >
    <validation-observer
      :ref="`${form.id}-form`"
    >
      <b-row align-v="end">
        <b-col cols="12">
          <div class="form-group">
            <validation-provider
              name="antecedentes"
              :rules="{ required: true }"
              
            >
              <VueEditor
                id="resultadoDoctorAssistant"
                class="vue_edition"
                :disabled="!canEdit"
                v-model="form.fields.resultadoDoctorAssistant"
                :editorToolbar="customToolbar"
              />
            </validation-provider>
          </div>
        </b-col>
      </b-row>
      <div class="edit-area">
        <div class="btn-container">
        </div>
      </div>
    </validation-observer>
    <b-row>
      <b-col>
      </b-col>
    </b-row>
  </FormWrapper>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import FormWrapper from '@/components/Attendance/Forms/FormWrapper'
import { VueEditor } from "vue2-editor"

export default {
  components: {
    FormWrapper,
    VueEditor,
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.resultadoDoctorAssistant,
    })
  },
  created() {
  },
  data() {
    return {
      fields: this.getDefaultFields(),
      editingIndex: null,
      customToolbar: [
          [{ header: [false, 1, 2, 3, 4, 5, 6] }],
          ["bold", "underline", "strike"], // toggled buttons
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" }
          ],
          ["blockquote", "code-block"],
          [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ],
    }
  },
  methods: {
    ...mapActions('attendance/form', ['updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/resultadoDoctorAssistant', ['handleFields', 'handleProps']),
    getDefaultFields() {
      return {
        hipoteseDiagnostica: null,
      }
    },
    saveItem() {
      const value = [...this.form.value]
      if (this.editingIndex !== null) {
        value.splice(this.editingIndex, 1)
      }

      value.push(this.fields)
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
      this.fields = this.getDefaultFields()
      this.editingIndex = null
    },
    editItem(value, index) {
      this.fields = { ...value }
      this.editingIndex = index
    },
    removeItem(index) {
      const value = [...this.form.value]
      value.splice(index, 1)
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      this.form.value.forEach((key,index) => {
        this.removeItem(index)
      });
    }
  },
}
</script>
<style lang="scss" scoped>
.button {
  height: 38px;
  margin-bottom: 24px;
}

.vue_edition {
  word-break: break-all;
  background-color: white;

  :deep(.ql-editor) {
    min-height: 100px !important;
  }
}

.edit-area {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: end;

  .btn-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: end;
    height: 45px;

    button {
      width: 150px;
      margin-left: 30px;
    }

    .remove {
      margin-top: 0.5rem;
      color: var(--states-error);
    }

    button:nth-child(2) {
      width: 350px;
    }
  }
}
</style>
